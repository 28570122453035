<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{ page_name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--内容-->
    <div class="page-content-x">
      <!--搜索-->
      <div v-show="issearch" class="page-content-search">
        <el-form
          ref="form"
          :model="search"
          size="medium"
          :label-width="this.env.search_label_width"
        >
          <el-row>
            <el-col :span="6">
              <el-form-item label="团长昵称">
                <el-input v-model="search.user_nick"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="团长手机号">
                <el-input v-model="search.phone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="状态">
                <el-select
                  v-model="search.state"
                  placeholder="请选择类型"
                  style="width: 100%"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option label="拼团中" :value="1"></el-option>
                  <el-option label="拼团成功" :value="2"></el-option>
                  <el-option label="拼团失败" :value="5"></el-option>
                  <el-option label="拼团取消" :value="-1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="page-content-search-button">
            <el-col :span="24">
              <el-form-item>
                <el-button
                  icon="el-icon-search"
                  size="medium"
                  type="primary"
                  @click="is_search"
                  >搜索
                </el-button>
                <el-button icon="el-icon-circle-close" size="medium" @click="init"
                  >清空搜索</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!--标题-->
      <el-row>
        <el-col :span="12"
          ><h3>{{ page_name }}</h3></el-col
        >
        <el-col :span="12">
          <div class="top-operation-button">
            <el-button
              v-if="issearch"
              icon="el-icon-caret-top"
              size="medium"
              type="primary"
              @click="issearch = !issearch"
              >隐藏搜索
            </el-button>
            <el-button
              v-else
              icon="el-icon-caret-bottom"
              size="medium"
              type="primary"
              @click="issearch = !issearch"
              >显示搜索
            </el-button>
          </div>
        </el-col>
      </el-row>
      <div style="height: 20px"></div>
      <!--列表-->
      <el-table
        size="medium"
        border
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
      >
        <!--                <el-table-column
                        prop="company_name"
                        label="所属企业"
                        width="200">
                </el-table-column>-->
        <el-table-column prop="groupbuy_name" label="拼团名称" width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.groupbuy_name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="lesson_name" label="状态" width="200">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.state === 1" size="mini">拼团中</el-tag>
            <el-tag v-if="scope.row.state === 2" size="mini" type="success"
              >拼团成功</el-tag
            >
            <el-tag v-if="scope.row.state === 5" size="mini" type="info">拼团失败</el-tag>
            <el-tag v-if="scope.row.state === -1" size="mini" type="info">拼团取消</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="user_nick" label="团长昵称"> </el-table-column>
        <el-table-column prop="phone" label="团长手机号" width="180"> </el-table-column>
        <el-table-column prop="price" label="价格" width="180">
          <template slot-scope="scope">
            <span>￥{{ scope.row.price }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="sum" label="参与人数"> </el-table-column>
        <el-table-column prop="quota" label="成团人数"> </el-table-column>
        <el-table-column prop="create_time" label="发起时间" width="180">
        </el-table-column>
        <el-table-column prop="end_time" label="结束时间" width="180"> </el-table-column>
        <el-table-column prop="update_time" label="更新时间" width="180">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="130">
          <template slot-scope="scope">
            <el-button
              v-if="is_auth('marketing.groupbuy.getorderlist')"
              @click="tosee(scope.row.groupbuy_initiate_uuid)"
              size="mini"
              >查看订单
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="height: 20px"></div>
      <!--分页-->
      <el-pagination
        @current-change="getlist"
        :page-size="this.env.pageSize"
        :pager-count="7"
        background
        layout="prev, pager, next, total"
        :current-page.sync="page"
        :total="count"
      >
      </el-pagination>
    </div>
    <!--订单内容-->
    <el-dialog
      v-if="is_auth('marketing.groupbuy.getorderlist')"
      v-loading="loading"
      title="拼团订单"
      :visible.sync="order_dialog"
      width="70%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <el-row>
        <OrderList ref="order_list" />
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import OrderList from "./page/OrderList.vue";
export default {
  components: {
    OrderList,
  },
  data() {
    return {
      page_name: "拼团记录",
      groupbuy_uuid: "",
      loading: true, // 加载状态
      issearch: true, // 搜索是否展示
      search: {}, // 搜索内容
      count: 0, // 数据总条数
      page: 1, // 当前页数
      tableData: [], // 列表内容
      order_dialog: false, // 是否显示订单弹窗
    };
  },
  // 创建
  created() {
    this.init();
  },
  // 安装
  mounted() {},
  methods: {
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 初始化
    init() {
      let groupbuy_uuid = this.$route.query.groupbuy_uuid;
      if (groupbuy_uuid !== undefined) {
        this.groupbuy_uuid = groupbuy_uuid;
      }
      this.search = {
        lesson_name: "",
        publish_state: "",
        lesson_tag_s: "",
      };
      this.is_search();
    },
    //搜索
    is_search() {
      this.page = 1;
      this.getlist();
    },
    // 获取列表
    getlist() {
      let postdata = {
        api_name: "marketing.groupbuy.getinitiatelist",
        token: this.Tool.get_l_cache("token"),
        groupbuy_uuid: this.groupbuy_uuid,
        pagesize: this.env.pageSize,
        page: this.page,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      this.Tool.post_data("merchant", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tableData = json.data.list;
          this.count = json.data.count;
        } else {
          this.tableData = [];
          this.count = 0;
          this.Tool.errormes(json);
        }
      });
    },
    // 查看订单
    tosee(groupbuy_initiate_uuid = "") {
      this.order_dialog = true;
      let thi = this;
      setTimeout(function () {
        thi.$refs.order_list.init(groupbuy_initiate_uuid);
      }, 300);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*表格不换行*/
>>> .el-table__body .cell {
  white-space: nowrap;
  /*overflow: initial;*/
}

.form-tag_colour-div {
  min-height: 200px;
}
</style>
